import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { Element } from "react-scroll"
import remark from "remark"
import recommended from "remark-preset-lint-recommended"
import remarkHtml from "remark-html"
import BoxRenderer from "../components/BoxRenderer"
import Topbar from "../components/Topbar"
import BoxContact from "../components/BoxContact"
import Privacy from "../components/Privacy"
import "../projects.scss"
import "prismjs/themes/prism-tomorrow.css"
import "../prism-override.scss"
import locales from "../constants/locales"
import ogImage from "../assets/images/og-logo.jpg"
import ProjectList from "../components/ProjectList"

class Projects extends Component {
  constructor(props) {
    super(props)
    this.state = {
      item: false,
      scrollPosition: 0,
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.listenToScroll, { passive: true })
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.listenToScroll, {
      passive: true,
    })
  }

  listenToScroll = () => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    let height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight
    let scrolled = (winScroll / height) * 100

    this.setState({
      scrollPosition: scrolled,
    })
  }

  getMenuVoices = () => {
    const {
      pageContext: { locale },
    } = this.props
    const { ARTICLE, PROJECTS, CONTACT } = locales[locale].textEntries
    return [ARTICLE, PROJECTS, CONTACT]
  }

  getMyProjects = () => {
    const {
      data,
      pageContext: { locale },
    } = this.props
    const { MORE_INFORMATIONS, MYPROJECTS_CLICK_HERE } = locales[
      locale
    ].textEntries

    let projects = locales[locale].projects(data)

    let articles = data.projects.nodes.map(
      (
        { frontmatter: { title, date, intro, path, image, language }, html },
        index
      ) => {
        if (language === locale) {
          return {
            key: index,
            years: date.toString(),
            position: MYPROJECTS_CLICK_HERE,
            title,
            text: remark()
              .use(recommended)
              .use(remarkHtml)
              .processSync(intro)
              .toString(),
            url: path,
            img: image.childImageSharp.fluid,
            animated: false,
            button: MORE_INFORMATIONS,
          }
        }
      }
    )

    for (let i = 0; i < articles.length; i++) {
      if (!articles[i]) articles.splice(i, 1)
    }

    projects = projects.concat(articles)

    projects = projects.sort((a, b) => {
      if (a.years > b.years) return -1
      if (a.years < b.years) return 1
      return 0
    })

    for (let i = 0; i < projects.length; i++) {
      if (!projects[i]) projects.splice(i, 1)
    }

    return projects
  }

  render() {
    const { scrollPosition } = this.state
    const {
      data,
      pageContext: { locale },
    } = this.props
    const {
      frontmatter: { title, date, path, image, intro, project },
      html,
    } = data.project
    const { ARTICLE, PROJECTS, KEYWORDS, CONTACT, CONTACT_ME } = locales[
      locale
    ].textEntries
    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={intro.split("*").join("")} />
          <meta name="keywords" content={KEYWORDS} />
          <meta name="robots" content="index, follow" />
          <meta http-equiv="content-language" content={locale} />
          <meta http-equiv="content-type" content="text/html; charset=utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://www.nks-developer.com/" />
          <meta property="og:title" content={title} />
          <meta property="og:image" content={ogImage} />
          <meta property="og:description" content={intro.split("*").join("")} />
          <meta
            property="og:locale"
            content={locale + "_" + locale.toUpperCase()}
          />
        </Helmet>
        <Topbar
          scrollPosition={scrollPosition}
          voices={this.getMenuVoices()}
          locale={locale}
          path={path}
          languageUrl={project}
        >
          <li
            onClick={() => {
              document.body.style = ""
              navigate("/" + (!locales[locale].default ? locale : ""))
            }}
          >
            Home
          </li>
        </Topbar>
        <main>
          <Element name={ARTICLE}>
            <div className="ImageBackgroundContainer">
              <Img
                fluid={image.childImageSharp.fluid}
                className="ImageBackground"
                alt="Fullstack Web Developer"
              />
            </div>
          </Element>

          <div className="ResponsiveContainer ProjectsPage">
            <BoxRenderer>
              <div className="LabelContainer">
                {PROJECTS} - {date}
              </div>
              <h1>{title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: html,
                }}
              ></div>
            </BoxRenderer>

            <ProjectList
              projects={this.getMyProjects()}
              locale={locales[locale].textEntries}
            />

            <Element name={CONTACT}>
              <h4 className="TitleText ContactTitle">{CONTACT_ME}</h4>
            </Element>
            <BoxContact lang={locale} className="show" />
          </div>
        </main>

        <Privacy locale={locale}></Privacy>
      </div>
    )
  }
}

export default Projects

export const query = graphql`
  query($path: String!) {
    project: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        date
        path
        intro
        language
        project
        image {
          childImageSharp {
            fluid(
              traceSVG: {
                color: "#27292e"
                turnPolicy: TURNPOLICY_MINORITY
                blackOnWhite: false
              }
              maxWidth: 2500
            ) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }

    projects: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/markdown_projects/" } }
    ) {
      nodes {
        html
        frontmatter {
          title
          date
          path
          language
          intro
          image {
            childImageSharp {
              fluid(
                traceSVG: {
                  color: "#27292e"
                  turnPolicy: TURNPOLICY_MINORITY
                  blackOnWhite: false
                }
                maxWidth: 1000
              ) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }

    tekimaCablesStore: file(relativePath: { eq: "projects/tekima-cables-store.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    tekimaLabelsStore: file(relativePath: { eq: "projects/tekima-labels-store.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    tekimaTubingsStore: file(relativePath: { eq: "projects/tekima-tubings-store.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    idiProject: file(relativePath: { eq: "projects/idiproject.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    museoFumetto: file(relativePath: { eq: "projects/museo-fumetto.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    immobilbiella: file(relativePath: { eq: "projects/immobilbiella.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    docilia: file(relativePath: { eq: "projects/docilia-restauri.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    azimut: file(relativePath: { eq: "projects/azimut.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    nimrod: file(relativePath: { eq: "projects/nimrod.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    tris: file(relativePath: { eq: "projects/tic-tac-toe.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    honda: file(relativePath: { eq: "projects/honda.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    jinni: file(relativePath: { eq: "projects/jinni.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    game2048: file(relativePath: { eq: "projects/2048.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    weatherWebApp: file(relativePath: { eq: "projects/weather-web-app.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }

    adventOfCode2022: file(relativePath: { eq: "projects/advent-of-code.jpg" }) {
      childImageSharp {
        fluid(
          traceSVG: {
            color: "#27292e"
            turnPolicy: TURNPOLICY_MINORITY
            blackOnWhite: false
          }
          maxWidth: 1000
        ) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
